'use client';

import React from 'react';
import Footer from './footer';
import Header from './header';
import { cn } from '@nextui-org/react';
import { LocaleProps } from '@/typings/next-page';

const Layout = ({
  children,
  locale,
}: React.PropsWithChildren<LocaleProps['params']>) => {
  return (
    <React.Fragment>
      <Header locale={locale} />
      <main className={cn('large-section')}>{children}</main>
      <Footer />
    </React.Fragment>
  );
};
export default Layout;
