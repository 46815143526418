import { tv } from '@nextui-org/react';

export const headerContainer = tv({
  // base: 'transition-all',
  base: 'sticky top-0 transition-all sm:py-4',
  variants: {
    transparent: {
      true: 'bg-transparent text-default-50 backdrop-blur-none',
    },
  },
});
export const headerLinks = tv({
  base: 'p-0 px-1 bg-transparent data-[hover=true]:bg-transparent min-w-fit text-base font-medium transition-all text-lg font-medium',
  variants: {
    transparent: {
      false: 'text-default-900',
    },
  },
});
