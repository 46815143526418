'use client';

import { cn } from '@nextui-org/react';
import { useParams } from 'next/navigation';
import React, { useTransition } from 'react';
import { languages } from '@/config/languages';
import { useRouter, usePathname } from '@/navigation';

type Props = {
  currentLocale: string;
  className?: string;
};

export default function LocaleSwitcherSelect({
  currentLocale,
  ...rest
}: Props) {
  const router = useRouter();
  const params = useParams();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();

  const onSelectChange = React.useCallback(
    (locale: string) => {
      startTransition(() => {
        router.replace(
          // @ts-expect-error -- TypeScript will validate that only known `params`
          // are used in combination with a given `pathname`. Since the two will
          // always match for the current route, we can skip runtime checks.
          { pathname, params },
          { locale }
        );
      });
    },
    [params, pathname, router]
  );

  return (
    <div className={cn('min-w-36 shadow-none')}>
      <select
        {...rest}
        className={cn('w-full bg-default-50 p-1 rounded-lg', rest?.className)}
        value={currentLocale}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onSelectChange(event.target.value);
        }}
        disabled={isPending}
        aria-label="Locale Switcher"
      >
        {languages.map((language) => (
          <option key={language.id} value={language.id}>
            {language.title}
          </option>
        ))}
      </select>
    </div>
  );
}
