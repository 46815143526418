import React from 'react';
import Image from 'next/image';
import { Link } from '@nextui-org/react';
import { IcRoundWhatsapp } from '@/components/icons/footer/IcRoundWhatsapp';
import { FluentMail24Regular } from '@/components/icons/footer/FluentMail24Regular';
import { PhPhoneLight } from '../../components/icons/footer/PhPhoneLight';
import { Link as NextLink } from '@/navigation';
import { useTranslations } from 'next-intl';

const Footer = () => {
  const t = useTranslations('footer');

  const footerLinks = [
    {
      section: t('section.products-and-services'),
      links: [
        {
          name: t('links.garden-centre-advice.name'),
          href: t('links.garden-centre-advice.href'),
        },
        {
          name: t('links.biophilic-design.name'),
          href: t('links.biophilic-design.href'),
        },
        {
          name: t('links.retail-nursery-design.name'),
          href: t('links.retail-nursery-design.href'),
        },
        {
          name: t('links.botanical-garden-and-parc-design.name'),
          href: t('links.botanical-garden-and-parc-design.href'),
        },
        {
          name: t('links.all-products-and-services.name'),
          href: t('links.all-products-and-services.href'),
        },
      ],
    },
    {
      section: t('section.legal'),
      links: [
        {
          name: t('links.privacy-policy.name'),
          href: t('links.privacy-policy.href'),
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <footer className="flex w-full flex-col footer-background">
        {/* Test */}
        <div className="flex flex-col gap-8 section px-6 pb-8 pt-16 sm:pt-24 lg:px-6 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 md:pr-8">
              <div className="flex items-center justify-start">
                <Image height={64} width={80} alt="logo" src={'/logo-gc.svg'} />
              </div>
              <p className="text-small text-default-300">
                <Link
                  as={NextLink}
                  size="sm"
                  href="/"
                  className="font-semibold text-default-300"
                >
                  {t('content.organization.title')}
                </Link>{' '}
                {t('content.organization.description')}
              </p>
              <ul className="flex flex-col gap-1">
                <li className="flex items-center gap-1 py-2 text-default-100">
                  <PhPhoneLight className="h-5 w-5 " />
                  <Link
                    as={NextLink}
                    size="sm"
                    href="tel:+316 487 681 46"
                    className="text-default-100"
                  >
                    {t('content.contact.phone')}
                  </Link>
                </li>
                <li className="flex items-center gap-1 py-2 text-default-100">
                  <IcRoundWhatsapp className="h-5 w-5 " />
                  <Link
                    as={NextLink}
                    size="sm"
                    target="_blank"
                    href="http://api.whatsapp.com/send?phone=31648768146&text=Message%20from%20Greenconceptors.com:%20"
                    className="text-default-100"
                  >
                    {t('content.contact.whatsapp')}
                  </Link>
                </li>
                <li className="flex items-center gap-1 py-2 text-default-100">
                  <FluentMail24Regular className="h-5 w-5 " />
                  <Link
                    as={NextLink}
                    size="sm"
                    href="mailto:info@greenconceptors.com"
                    className="text-default-100"
                  >
                    {t('content.contact.mail')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-16 grid sm:grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              {footerLinks.map((item, index) => (
                <div key={index}>
                  <p className="uppercase text-base sm:text-lg font-semibold text-default-50">
                    {item.section}
                  </p>
                  <ul className="mt-6 space-y-6">
                    {item.links.map((link, index) => (
                      <React.Fragment key={index}>
                        <li>
                          <Link
                            as={NextLink}
                            href={link.href}
                            color="foreground"
                            className="text-base text-default-300"
                          >
                            {link.name}
                          </Link>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full section flex flex-col flex-1">
          <div className="w-full flex-1 flex justify-between gap-2 py-8 border-t border-default-800 flex-wrap">
            <p className="text-small text-default-300">
              © {new Date().getFullYear()} {process.env.NEXT_PUBLIC_APP_NAME}.{' '}
              {t('content.copyright.text')}
            </p>
            <p className="text-small text-default-300">
              <a
                href="http://Capello-dgtl.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                {t('content.developer.text')}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
